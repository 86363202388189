<template>
    <div class="mb-12 space-y-12">
        <div class="relative overflow-hidden bg-gradient-to-t from-base-200">
            <NuxtPicture src="/images/categories/cupcake.jpg"
                         loading="lazy"
                         :img-attrs="{
                             style: 'clip-path: polygon(84% 0, 100% 0%, 100% 100%, 36% 100%);',
                             class: 'h-full w-full object-center object-cover'
                         }"
                         class="absolute inset-y-0 right-0 hidden opacity-20 xs:block md:hidden" />
            <div class="flex flex-row-reverse flex-wrap items-center justify-around gap-4 p-12 md:flex-row">
                <section class="relative w-full space-y-6 md:w-1/2">
                    <h1 class="text-5xl font-medium">
                        <span class="text-primary">Sweetness</span>
                        <br>
                        <span class="text-primary-content/70">is my specialty!</span>
                    </h1>
                    <p>
                        Indulge in my delightful confections that are not only visually enchanting but also crafted
                        with the finest ingredients to ensure each bite is a moment of pure bliss. Welcome to a world
                        where every occasion becomes sweeter with Cina's Delicious Cakes.
                    </p>
                    <NuxtLink :to="{ name: 'products' }"
                              class="btn btn-primary capitalize hover:!text-primary-content/70">
                        Browse Products
                    </NuxtLink>
                </section>
                <div class="hidden w-full grid-cols-2 place-items-center items-center gap-6 md:grid md:w-5/12">
                    <NuxtPicture src="/images/categories/bread.jpg"
                                 loading="lazy"
                                 height="600"
                                 width="500"
                                 alt="Bread"
                                 sizes="140px md:210px xl:350"
                                 :img-attrs="{ class: 'rounded-2xl' }"
                                 class="row-span-2" />
                    <NuxtPicture src="/images/categories/dessert.jpg"
                                 loading="lazy"
                                 height="350"
                                 alt="Dessert"
                                 width="200"
                                 sizes="140px md:210px xl:350"
                                 :img-attrs="{ class: 'rounded-2xl' }" />
                    <NuxtPicture src="/images/categories/pie.jpg"
                                 loading="lazy"
                                 height="200"
                                 alt="Pie"
                                 width="200"
                                 sizes="140px md:210px xl:350"
                                 :img-attrs="{ class: 'rounded-2xl' }" />
                </div>
            </div>
        </div>

        <PublicCategoryGrid class="mx-2" />

        <div class="mx-2 flex flex-col gap-6 sm:container sm:mx-auto">
            <PublicReview v-for="(review, i) in reviews"
                          v-once
                          v-bind="review"
                          :key="review.author"
                          :mirror="i % 2 === 0"
                          :class="{ 'self-end': i % 2 === 0 }" />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, markRaw, onMounted } from 'vue';
import { requestIdleCallback } from '#imports';
import useProductStore from '~/stores/products';
import { description, title } from '~/fixtures';

export default defineComponent({
    // eslint-disable-next-line vue/match-component-file-name
    name: 'Home',

    setup: () => {
        const reviews = markRaw([
            {
                quote: 'I highly recommend Cina for beautiful, unique handmade cakes. She is very professional and ' +
                    'the cakes always taste delicious. I will be ordering many more this year for family occasions!',

                author: 'Eden M.',
                link: 'https://www.facebook.com/eden.mullane/posts/pfbid0BELiee87mH6QqH7MrxBvs2nx7M2WXQaY85jvcxt7Fi' +
                    '2u7yxbTA4sS6Ddt354DKvjl'
            },
            {
                quote: 'Delicious and gorgeous cake. Just exactly how I imagined. I had a very specific birthday cake' +
                    ' in mind and Cina delivered it in timely fashion.\n' +
                    'It’s not only beautiful but tasty. You can definitely taste it is homemade from quality' +
                    ' ingredients. Cina is super responsive and is happy to listen to how you’d like the cake to be' +
                    ' made. Would definitely order again in the future to make special occasions extra special.',

                author: 'Csenge R.',
                link: 'https://www.facebook.com/csenge.racz.18/posts/pfbid0DBNbrQGrdNFXmh4CQJAT8q1zJRgDsDSfVfbyh2GmS9' +
                    'jS7fKPe97y2XqucQhw9VQCl'
            },
            {
                quote: 'I have been lucky to try Cina\'s cake and cannot recommend it enough! Everyone love it! The ' +
                    'cake look and taste better than what you wished for, it was packed in elegant box and it is made' +
                    ' by a great professional and individual! Cina is a absolutely brilliant & a very lovely lady.' +
                    ' Her attention to detail is incredible & 100% recommend to everyone! Thank you Cina!',

                author: 'Kamila M.',
                link: 'https://www.facebook.com/permalink.php?story_fbid=pfbid02fgwYtYHfi7GRUJDUCUHSXk8o526NE4o2RovS' +
                    'ZUPcq84ECZneKNJChi75CLyBaohYl&id=100010335305396'
            }
        ]);

        if (import.meta.client) {
            onMounted(() => {
                const productStore = useProductStore();
                requestIdleCallback(() => void productStore.loadProducts());
            });
        }

        useSeoMeta({
            title,
            description,
            ogTitle: title,
            ogDescription: description,
            twitterTitle: title,
            twitterDescription: description
        });

        return {
            reviews
        };
    }
});
</script>
